/**
 * admin header component
 */
/* eslint-disable */
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import MenuItems from 'routes/MenuItems';

function SidebarContent() {

	const [navLinks] = useState(MenuItems);
	const [activeMenuItem, setActiveMenuItem] = useState('');
	const getPlanName = (name) => {
		let newName = name.replace("-", " ");
		return newName
	}

	const location = useLocation();

	const handleMenuItemClick = (menuTitle) => {


		// Update the active menu item in state and localStorage
		setActiveMenuItem(menuTitle);
		localStorage.setItem('activeMenuItem', menuTitle);
	};

	const currentPath = location.pathname;


	useEffect(() => {
		const storedActiveMenuItem = localStorage.getItem('activeMenuItem');
		if (storedActiveMenuItem) {
			setActiveMenuItem(storedActiveMenuItem);
		}
	}, []);



	// return (
	// 	<div className="icon-menu-wrap">
	// 		<ul className="main-menu">
	// 			{navLinks && navLinks.map((navlink, index) => (
	// 				<Fragment key={index}>
	// 					{navlink.child_routes !== null ?
	// 						<li className="menu-item">
	// 							<Link to={navlink.path}>
	// 								<i className="material-icons-outlined">{navlink.icon}</i>
	// 							</Link>
	// 							<div className="sub-menu-wrap">
	// 								<div className="sub-menu-header">
	// 									{navlink.menu_title}
	// 								</div>
	// 								<div className="sub-menu-list">
	// 									<ul className="sub-menu">
	// 										{navlink.child_routes.map((subMenu, subKey) => (
	// 											<li key={subKey}>
	// 												<Link to={subMenu.path}>
	// 													{subMenu.menu_title}
	// 												</Link>
	// 											</li>
	// 										))}
	// 									</ul>
	// 								</div>
	// 							</div>
	// 						</li>
	// 						:
	// 						<li className="menu-item">
	// 							<Tooltip  title={navlink.menu_title} placement="right">
	// 								<a href={navlink.path}>
	// 									<i className="material-icons-outlined">{navlink.icon}</i>
	// 								</a>
	// 							</Tooltip>
	// 						</li>
	// 					}
	// 				</Fragment>
	// 			))}
	// 		</ul>
	// 	</div>
	// );


	return (
		<div className="icon-menu-wrap">
			<ul className="main-menu">
				{navLinks &&
					navLinks.map((navlink, index) => (
						<Fragment key={index}>
							{navlink.child_routes !== null ? (
								<li className="menu-item">
									<Link
										to={navlink.path}
										onClick={() => handleMenuItemClick(navlink.menu_title)}
										className={navlink.menu_title === activeMenuItem ? 'active' : ''}
									>
										<i className="material-icons-outlined">{navlink.icon}</i>
									</Link>
									<div className="sub-menu-wrap">
										<div className="sub-menu-header">{navlink.menu_title}</div>
										<div className="sub-menu-list">
											<ul className="sub-menu">
												{navlink.child_routes.map((subMenu, subKey) => (
													<li key={subKey}>
														<Link to={subMenu.path}>{subMenu.menu_title}</Link>
													</li>
												))}
											</ul>
										</div>
									</div>
								</li>
							) : (
								<li className="menu-item">
									<Tooltip title={navlink.menu_title} placement="right">
										<a
											href={navlink.path}
											onClick={() => handleMenuItemClick(navlink.menu_title)}
											className={navlink.menu_title === activeMenuItem ? 'active' : ''}
										>
											<i className="material-icons-outlined">{navlink.icon}</i>
										</a>
									</Tooltip>
								</li>
							)}
						</Fragment>
					))}
			</ul>
		</div>
	);
}


export default SidebarContent;